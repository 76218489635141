@font-face {
  font-family: Suisse;
  src: url(https://join.talented.no/fonts/SuisseIntl-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: Suisse;
  src: url(https://join.talented.no/fonts/SuisseIntl-Bold.otf);
  font-weight: bold;
}

html {
  font-size: 18px;

  @media (min-width: 900px) {
    font-size: 24px;
  }
}

body {
  font-family: 'Suisse', Arial, sans-serif;
  font-weight: normal;
  line-height: 1.45;
  color: #0F1108;
}

h1 {
  font-size: 2.2rem;
  margin: 0;
  font-weight: 600;
  line-height: 1.15;
  letter-spacing: 1.5px;

  @media (min-width: 900px) {
    font-size: 2.488rem;
  }
}

h2 {
  font-size: 1.4rem;
  margin: 0.5rem 0;
  line-height: 1.15;
  font-weight: 200;

  @media (min-width: 900px) {
    margin: 1rem 0;
    font-size: 1.44rem;
  }
}

p {
  margin-top: 0.25rem;

  @media (min-width: 900px) {
    margin-top: 0.5rem;
  }
}

a {
  color: #0F1108;
  text-decoration: none;
  border-bottom: currentcolor 1px solid;
}

.container {
  max-width: 520px;
  margin: 0 auto;
  padding: 0 1rem 100px 1rem;

  @media (min-width: 900px) {
    max-width: 650px;
    padding: 0 1rem 90px 1rem;
  }
}

.app {
  position: relative;
  background: #F2E9DE;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 2rem);
  margin: 1rem;
  overflow: hidden;
}

.grab-zone-wrapper {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%) translateY(50%);
}

.grab-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 700px;
  border-radius: 50%;

  &__danger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 400px;
    border-radius: 50%;
  }
}

.grabber {
  position: relative;
  width: 100px;
  height: 100px;

  &__arm-wrapper {
    position: absolute;
    top: -80px;
    width: 24px;
    height: 260px;
  }

  &__arm {
    position: relative;
    width: 24px;
    height: 200px;
    background: #7D9A9E;
    border-radius: 20px;
    overflow: visible;
    transform: translateY(100%);
    transition: transform 0.2s ease;
  }

  &__hand {
    display: block;
    position: absolute;
    top: -12px;
    right: -10px;
    transform: scale(1.4) rotate(-10deg) translateY(100%);
    transform-origin: bottom center;
    transition: transform 0.3s ease;
  }

  &__face {
    position: absolute;
    width: 75px;
    height: 84px;
    right: 5%;
    transition: transform 0.3s ease;
  }

  &__body {
    position: absolute;
    top: 50%;
    left: 0;
    width: 110px;
    height: 95px;
    border-radius: 50%;
    background: #7D9A9E;
    transition: transform 0.3s ease;
  }

  &--waiting {
    .grabber__hand {
      transform: scale(1.4) rotate(-10deg);
    }

    .grabber__arm {
      transform: translateY(80%);
    }

    .grabber__face {
      transform: translateY(60%);
    }
  }

  &--stalking {
    .grabber__hand {
      transform: scale(1.4) rotate(-10deg);
    }

    .grabber__arm {
      transform: translateY(70%);
    }

    .grabber__face {
      transform: translateY(10%);
    }
  }

  &--grabbing {

    .grabber__face {
      transform: translateY(-40%) rotate(10deg);
    }

    .grabber__arm {
      transform: translateY(0%);
    }

    .grabber__body {
      transform: translateY(-20%);
    }

    .grabber__hand {
      transform: scale(1.7) rotate(10deg);
    }
  }

  &--grabbed {

    .grabber__arm {
      transition: transform 1s ease;
    }

    .grabber__hand {
      transition: transform 2.5s ease;
    }

    .grabber__face {
      transform: translateY(70%);
      transition: transform 1s ease;
    }

    .grabber__body {
      transform: translateY(50%);
      transition: transform 1s ease;
    }
  }

  &--extended {

    .grabber__arm {
      transform: translateY(-20%);
    }

    .grabber__face {
      transform: translateY(-60%) rotate(15deg);
    }

    .grabber__body {
      transform: translateY(-40%);
    }
  }

  &--shaka {
    .grabber__arm {
      transform: translateY(50%);
    }

    .grabber__hand {
      transform: scale(2.5) translateY(10%);
      animation: shaka 0.5s infinite alternate forwards;
      transform-origin: 55% 60%;
    }

    .grabber__face {
      transform: translateY(70%);
      transition: transform 1s ease;
    }

    .grabber__body {
      transform: translateY(50%);
      transition: transform 1s ease;
    }
  }
}

.trap-button {
  position: absolute;
  bottom: 80px;
  min-width: 125px;
  padding: 0.4rem 0.5rem;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #07f9a5;
}

@keyframes shaka {
  0% {
    transform: scale(2.5) translateY(0%) rotate(-20deg);
  }
  100% {
    transform: scale(2.5) translateY(0%) rotate(20deg);
  }
}
